export const getCategories = (data, key) => {
  return data.map(item => {
    return item[key]
  })
};
export const getSeries = (data, key) => {
  return data.map(item => {
    return item[key]
  })
};
export const getColorSentiment = (sentiment) => {
  const sentiments = [
    {text: 'neutral', color: 'text-warning'},
    {text: 'negative', color: 'text-danger'},
    {text: 'positive', color: 'text-primary'},
  ]

  const element = sentiments.find(item => item.text === sentiment)

  if (element)
    return element.color

  return 'text-info'
};
export const getColorDotProfile = (mark) => {
  const obj = {
    low: 'bg-danger',
    below_average: "bg-danger",
    poor: 'bg-danger',
    fair: 'bg-danger',
    average: 'bg-warning',
    good: 'bg-success',
    very_good: 'bg-success',
    excellent: 'bg-success',
    none: "bg-secondary"
  }
  return obj[mark]
};
export const statusVariant = (status) => {
  const statusColor = {
    onboarding: "light-primary",
    approved: "light-success",
    published: "light-success",
    producing: "light-warning",
    trading: "light-warning",
    submitted: "light-info",
    rejected: "light-danger",
    low: 'light-danger',
    below_average: "light-danger",
    poor: 'light-danger',
    fair: 'light-danger',
    average: 'light-warning',
    good: 'light-success',
    very_good: 'light-success',
    excellent: 'light-success',
    none: "light-secondary"
  };    
  return statusColor[status];
};
export const getStatus = (status) => {
  const types = {
    'approved': 'campaigns.approved',
    'pending': 'campaigns.pending',
    'rejected': 'campaigns.rejected',
    'onboarding': 'campaigns.onboarding',
    'trading': 'campaigns.trading',
    'producing': 'campaigns.producing',
    'submitted': 'campaigns.submitted',
    'published': 'campaigns.published',
  }
  if (types[status]) return types[status]
  return ''
};
export const statusVariantPaymentData = (has_payment_data) => {
  if (has_payment_data) return "light-success"
  else return "secondary"
};
export const getStatusContent = (status) => {
  if (status === 'content_pending') return 'text-warning'
  if (status === 'content_producing') return 'text-success'
  if (status === 'content_submitted') return 'text-primary'
  if (status === 'content_rejected') return 'text-danger'
  if (status === 'content_published') return 'text-success'
};